<template>
  <div>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon
        @click.stop="
          $store.state.navigationDrawer = !$store.state.navigationDrawer
        "
      />
      <v-toolbar-title>
        <slot name="title" />
      </v-toolbar-title>
      <v-layout align-center justify-end
                v-show="$store.state.menuRestriction">
        <v-text-field
          hide-details
          :placeholder="$t('search_products')"
          v-model="$store.state.productSearchConstants.productSearchText"
          prepend-inner-icon="search"
          style="max-width: 280px; margin-bottom: -2px"
          @keyup.enter="
            searchProduct($store.state.productSearchConstants.productSearchText)
          "
        />
        <v-btn
          icon
          text
          dark
          color="white"
          style="top: 3px; left: 2px;"
          @click="basketAction()"
        >
          <v-badge
            top
            color="accent"
            overlap
            :content="this.$store.state.badgeCounter"
            :value="this.$store.state.badgeCounter"
          >
            <v-icon dark>shopping_cart</v-icon>
          </v-badge>
        </v-btn>
        <v-btn
          icon
          style="top: 2px; left: 2px;"
          @click.stop="$store.state.buttonTry = !$store.state.buttonTry"
          v-show="$store.state.dashboardRestriction"
        >
          <v-icon dark>table_chart </v-icon>
        </v-btn>
        <slot name="sortMenu"></slot>
      </v-layout>
    </v-app-bar>
    <!--        <v-content style="background-color: blue">-->
    <slot name="content" />
    <!--        </v-content>-->
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PageTemplate",
  methods: {
    basketAction() {
      this.$router.push({ name: "Basket" });
    },
    searchProduct(text) {
      if (
        text.length !== 0 &&
        this.$router.currentRoute.name !== "SearchProduct"
      ) {
        this.$router.push({
          name: "SearchProduct",
          params: { productSearch: text },
        });
      } else {
        this.$store.dispatch("searchProduct");
      }
    },
    ...mapActions(["showProgressBar", "hideProgressBar"]),
  },
};
</script>

<style scoped></style>
