<template>
  <page-template>
    <template slot="title">{{ this.$t("favorite_products") }}</template>
    <template slot="content">
      <v-layout align-center justify-start row wrap style="margin: 0;">
        <product-view
          v-show="!noData"
          v-for="product in favoriteProducts"
          :key="product.key"
          :product="product"
        />
        <not-found v-show="noData" />

      </v-layout>
    </template>
    <template slot="sortMenu">
      <v-menu bottom left style="z-index: 10;">
        <template v-slot:activator="{ on }">
          <v-btn dark icon v-on="on">
            <v-icon>sort</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item @click="byNameASC()">
            <v-list-item-title>{{ this.$t("by_name_asc") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byNameDESC()">
            <v-list-item-title>{{ this.$t("by_name_desc") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byCodeASC()">
            <v-list-item-title>{{ this.$t("by_code_asc") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byCodeDESC()">
            <v-list-item-title>{{ this.$t("by_code_desc") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byGroup()">
            <v-list-item-title>{{ this.$t("by_group") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </page-template>
</template>

<script>
import Constants from "@/Constants";
import PageTemplate from "../../templates/PageTemplate";
import { mapActions } from "vuex";
import ProductView from "../../partial/ProductView";
import NotFound from "../scada/NotFound";
export default {
  name: "HighlightedProducts",
  components: { NotFound, PageTemplate, ProductView },
  created() {
    this.getFavoriteProducts();
  },
  data() {
    return {
      noData: false,
      favoriteProducts: [],
      favoriteArrayProducts: null,
    };
  },
  methods: {
    getFavoriteProducts() {
      this.$store.dispatch("showProgressBar")
      this.$http.get(Constants.apiMethod.favoriteProducts).then((response) => {
        this.favoriteArrayProducts = response.data;
        console.log(response.data);
        this.getProduct();
      });
    },

    getProduct() {
      this.favoriteArrayProducts.forEach((fav) => {
        this.$http.get(Constants.apiMethod.productDetail
              .concat(fav.productId)
              .concat("&includeRelatedObjects=true"))
          .then((response) => {
            this.favoriteProducts.push(response.data);
          });
      });
      this.$store.dispatch("hideProgressBar");
      console.log(this.favoriteProducts);
    },
    byNameASC() {
      this.highlightedProducts = this.highlightedProducts.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
    },
    byNameDESC() {
      this.highlightedProducts = this.highlightedProducts.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
    },
    byCodeASC() {
      this.highlightedProducts = this.highlightedProducts.sort((a, b) =>
        a.code > b.code ? 1 : -1
      );
    },
    byCodeDESC() {
      this.highlightedProducts = this.highlightedProducts.sort((a, b) =>
        a.code < b.code ? 1 : -1
      );
    },
    byGroup() {
      this.highlightedProducts = this.highlightedProducts.sort((a, b) =>
        a.productGroup.code < b.productGroup.code ? 1 : -1
      );
    },
    ...mapActions(["showProgressBar", "hideProgressBar", "loadUserProfile"]),
  },
};
</script>

<style scoped></style>
