<template>
  <div>
    <v-navigation-drawer
        v-model="$store.state.navigationDrawer"
        :mini-variant.sync="$store.state.navigationMini"
        width="300"
        class="elevation-6"
        app>

      <v-list-item two-line style="">
        <v-list-item-content style="padding-top: 0px">
          <div class="ma-2"></div>
          <img
              style="width: 256px;height: 100px; object-fit: contain;"
              :src="loadImg"
              class="menu-img"
              alt/>
          <div class="ma-3"></div>
          <v-list-item-subtitle style="color: black; padding-bottom: 2px" class="body-2">
            {{ this.$store.state.userProfile.name }}
            {{ this.$store.state.userProfile.lastName }}
          </v-list-item-subtitle>
          <v-list-item-subtitle style="color: black" class="body-2">{{ this.$store.state.userProfile.companyName }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider/>
      <v-list dense>
          <v-list-item to="/HighlightedProducts"
            v-show="$store.state.menuRestriction">
            <v-list-item-icon>
              <v-icon>local_activity</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                  this.$t("highlighted_products")
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/FavoriteProducts"
            v-show="$store.state.menuRestriction">
            <v-list-item-icon>
              <v-icon>grade</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                  this.$t("favorite_products")
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/LastPurchasedProducts"
            v-show="$store.state.menuRestriction">
            <v-list-item-icon>
              <v-icon>assignment</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                  this.$t("recently_purchased_products")
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/ProductGroups" @click="showProgressBar"
            v-show="$store.state.menuRestriction">
            <v-list-item-icon>
              <v-icon>format_align_right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                  this.$t("product_groups")
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        <v-list-item to="/Wholesaler" @click="showProgressBar"
                     v-show="!$store.state.menuRestriction">
          <v-list-item-icon>
            <v-icon>format_align_right</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
                this.$t("your_wholesaler")
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

          <v-divider
            v-show="! $store.state.menuRestriction"/>

        <v-subheader>{{ this.$t("my_account") }}</v-subheader>

        <v-list-item to="/UserProfile">
          <v-list-item-icon>
            <v-icon>account_box</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
                this.$t("my_information")
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/AccountActivities"
            v-show="$store.state.menuRestriction">
          <v-list-item-icon>
            <v-icon>ballot</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ this.$t("account_activities") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/Documents"
            v-show="$store.state.menuRestriction">
          <v-list-item-icon>
            <v-icon>playlist_add_check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ this.$t("documents") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/Settings">
          <v-list-item-icon>
            <v-icon>settings</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ this.$t("settings") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            v-show="$store.state.menuRestriction"
            @click="leaveWholesaler()"
        >
          <v-list-item-icon>
            <v-icon>reply</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
                this.$t("leave_wholesaler")
              }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="LogOut()">
          <v-list-item-icon>
            <v-icon>clear</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ this.$t("logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main style="">
      <v-container fluid style="margin: 0; padding: 8px">
        <router-view/>
      </v-container>
    </v-main>
  </div>
</template>

<script>

import Constants from "@/Constants";
import {mapActions} from "vuex";
import wholesalerConfig from "@/plugins/style/wholesalersConfig";

export default {
  name: "MainLayout",
  created() {
  },
  data() {
    return {
      loadImg:wholesalerConfig.loadMenuImg(window.location.host),
      navigator: true,
    };
  },
  methods: {
    LogOut() {
      this.$store.dispatch("showProgressBar");
      this.$http.delete(Constants.apiMethod.logout).then(
          (response) => {
            console.log(response.data);
            this.afterLogout();
          },
          (response) => {
            console.log(response.data);
            console.log("LOGOUT ERROR");
          }
      );
    },
    leaveWholesaler() {
      this.showProgressBar();
      this.$http.delete(Constants.apiMethod.leaveWholesaler).then(
          (response) => {
            console.log(response.data);
            localStorage.clear();
            this.$store.state.menuRestriction = false;
            this.$router.push({ path: "/Wholesaler" });
          },
          (response) => {
            console.log(response.data);
            console.log("Disconnect Wholesaler ERROR");
          }
      );
    },
    afterLogout() {
      this.$cookie.delete("bgtoken");
      this.$store.state.userProfile = null;
      this.$store.state.menuRestriction = false
      localStorage.clear();
      this.$router.push({name: "Login"});
    },
    ...mapActions(["showProgressBar", "hideProgressBar"]),
  },
};
</script>
<style scoped>
.menu-img {

  z-index: -0;
}
</style>
