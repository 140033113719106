import { render, staticRenderFns } from "./ProductView.vue?vue&type=template&id=1876ac3a&scoped=true&"
import script from "./ProductView.vue?vue&type=script&lang=js&"
export * from "./ProductView.vue?vue&type=script&lang=js&"
import style0 from "./ProductView.vue?vue&type=style&index=0&id=1876ac3a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1876ac3a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBtn,VCard,VCardActions,VCol,VFlex,VIcon,VImg,VLayout,VRow,VSnackbar,VSubheader})
