<template>
  <v-flex xs6 sm4 md3 lg3 xl2 style="padding: 8px;">
    <div class="box" style="margin: 2px;">
      <div class="ribbon ribbon-top-left"
           v-if="product.campaignCode !== null || product.campaignCode===''"
           @click="onClickProduct(product)">
        <span :style="ribbon" v-if="product.campaignCode === 'NEW'">{{ $t('new') }}</span>
        <span :style="ribbon" v-else-if="product.campaignCode === 'STOCK'">{{ $t('stock') }}</span>
        <span :style="ribbon" v-else-if="product.campaignCode === 'PROMO'">{{ $t('promotion') }}</span>
      </div>
    </div>
    <v-card class="elevation-8" style="border-radius: 5px;">
      <v-row align="center"
             justify="center"
             style="height: 240px; cursor: pointer;"
             @click="onClickProduct(product)"
      >
        <v-img contain
               src="../../../src/assets/box_opened_vector_.png"
               alt
               v-show="!imageShow"
               style="height: 100%;"></v-img>
        <v-img contain v-show="imageShow" :src="getImageUrl()" :style="style"/>

        <!--        product initial price value after added basket -->
        <v-layout v-if="product.initialSalePriceValue !== null"
                  v-show="actionBar"
                  class="elevation-4 accent"
                  style="position: absolute;
                         color: white;
                         padding: 2px;
                         width: auto;
                         bottom: 96px;
                         font-size: 14px;
                         border-radius: 2px;
                         padding-left: 8px;
                         padding-right: 8px;
                         right: 0;"
        >
          <v-col style="padding: 9px;">
            <v-row style="color: white;">
              <v-col cols="12" class="pa-0">
                {{ (product.initialSalePriceValue * product.packagingQuantity).toFixed(2) }}
                {{ $store.state.userProfile.currentWholesaler.settings.currencyCode }}
                {{ "KOL" }}
              </v-col>
            </v-row>
            <v-row style="color: white;" v-if="product.packagingQuantity !== 1">
              <v-col cols="12" class="pa-0">
                {{ product.initialSalePriceValue.toFixed(2) }}
                {{ $store.state.userProfile.currentWholesaler.settings.currencyCode }}
                {{ "Ad." }}
              </v-col>
            </v-row>
          </v-col>
        </v-layout>
      </v-row>

      <!--      product name area-->
      <v-layout style="height: 42px;">
        <div style="height: 45px;
                    width: auto;
                    text-align: left;
                    padding-left: 5px;
                    padding: 8px;
                    overflow: hidden;
                    font-family: Roboto, sans-serif;
                    font-size: 14.65px;
                    text-overflow: ellipsis;
                    white-space: nowrap;"
             :title="product.name">
          {{ product.code }} {{ product.name }}
        </div>
      </v-layout>
      <!--      product initial price area-->
      <v-card-actions
          style="; height: 52px; cursor: pointer;"
          class="accent"
          v-show="!actionBar"
          @click="onCardActions()">
        <v-layout>
          <v-col cols="12">
            <v-row style="color: white"
                   v-if="$store.state.userProfile.currentWholesaler.settings.stockDisplayMethod === 1">{{ $t("stock") }}
              {{ $t("quantity") }} : {{ product.stockQuantity }}
            </v-row>
            <v-row style="color: white;" v-if="product.packagingQuantity !== 1">
              <v-col cols="12" class="pa-0">
                {{ (product.initialSalePriceValue * product.packagingQuantity).toFixed(2) }}
                {{ $store.state.userProfile.currentWholesaler.settings.currencyCode}}
                {{ "KOL" }}
              </v-col>
            </v-row>
            <v-row style="color: white;">
              <v-col cols="12" class="pa-0">
                {{ product.initialSalePriceValue.toFixed(2) }}
                {{$store.state.userProfile.currentWholesaler.settings.currencyCode}}
                {{ "Ad." }}
              </v-col>
            </v-row>
          </v-col>
          <v-layout justify-end>
            <v-icon style="color: white;">add_shopping_cart</v-icon>
          </v-layout>
        </v-layout>
      </v-card-actions>

      <!--      product add basket area-->
      <v-card-actions
          style="; height: 52px;"
          class="primary"
          v-show="actionBar"
      >
        <v-layout align-space-between justify-space-around row fill-height>
          <v-btn text icon color="white" @click="decrease()">
            <v-icon>remove</v-icon>
          </v-btn>
          <v-subheader name="piece" style="margin: -5px; color: white;">{{
              piece
            }}
          </v-subheader>
          <v-btn text icon color="white" @click="increase()">
            <v-icon>add</v-icon>
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" center top color="accent" :multi-line="mode === 'multi-line'" :timeout="timeout"
                :vertical="mode === 'vertical'">
      <v-row align="center" justify="center">{{ text }}</v-row>
    </v-snackbar>
  </v-flex>
</template>

<script>

import {mapActions} from "vuex";
import Constants from "@/Constants";

export default {
  name: "ProductView",
  props: {
    product: null,
  },
  created() {
    if (this.product.campaignCode === "") {
      this.product.campaignCode = null;
    }
    if (localStorage.getItem("proWebBasket") == null || localStorage.getItem("proWebBasket") === "") {
      localStorage.setItem("proWebBasket", JSON.stringify([]));
    }
    this.proWebBasket = JSON.parse(localStorage.getItem("proWebBasket"));
    if (this.proWebBasket.find((s) => s.product.code === this.product.code)) {
      var searchPro = this.proWebBasket.find(
          (s) => s.product.code === this.product.code
      );
      this.actionBar = !this.actionBar;
      this.piece = searchPro.quantity;
    }
    this.$store.state.campaignColors.forEach((item) => {
      if (item.code === this.product.campaignCode) {
        this.ribbon = this.ribbon + item.color;
      }
    })
  },
  data() {
    return {
      ribbon: "background: #",
      snackbar: false,
      mode: "",
      timeout: 3000,
      text: null,
      actionBar: false,
      index: null,
      imageShow: true,
      generatedProduct: {},
      proWebBasket: {},
      piece: 0,
      style: "",
    };
  },
  methods: {
    onClickProduct(product) {
      if (product.initialSalePriceValue !== null) {
        this.$router.push({
          name: "ProductDetail",
          params: {id: product.id},
        });
        this.showProgressBar();
      }
    },
    decrease() {
      this.piece--;
    },
    increase() {
      this.piece++;
    },
    getImageUrl() {
      var result = "";
      if (this.product.productImages.length > 0) {
        if (this.product.productImages[0].mediumThumbnailUrl) {
          this.style = "height:45%;";
          result = this.$store.state.userProfile.currentWholesaler.fileUrlRoot.concat(
              this.product.productImages[0].mediumThumbnailUrl
          );
        } else if (this.product.productImages[0].smallThumbnailUrl) {
          result = this.$store.state.userProfile.currentWholesaler.fileUrlRoot.concat(
              this.product.productImages[0].smallThumbnailUrl
          );
          this.style = "height:70%;";
        } else if (this.product.productImages[0].largeThumbnailUrl) {
          result = this.$store.state.userProfile.currentWholesaler.fileUrlRoot.concat(
              this.product.productImages[0].largeThumbnailUrl
          );
        } else {
          result = this.$store.state.userProfile.currentWholesaler.fileUrlRoot.concat(
              this.product.productImages[0].url
          );
          this.style = "height:80%;margin-top:10px";
        }
      } else {
        this.imageShow = false;
      }
      return result;
    },
    onCardActions() {
      this.actionBar = !this.actionBar;
      this.piece++;
    },
    ...mapActions(["showProgressBar", "hideProgressBar"]),
  },
  watch: {
    piece() {
      if (this.piece === 0) {
        this.proWebBasket = JSON.parse(localStorage.getItem("proWebBasket"));
        this.index = this.proWebBasket.findIndex(
            (s) => s.product.code === this.generatedProduct.product.code
        );
        this.proWebBasket.splice(this.index, 1);
        console.log(this.proWebBasket);
        localStorage.setItem("proWebBasket", JSON.stringify(this.proWebBasket));
        this.actionBar = !this.actionBar;
        if (this.product.stockQuantity !==0 ){
          this.$store.state.badgeCounter--;
          this.text = this.$t("remove_basket_item_success_message");
          this.snackbar = true;
        }
      } else if (this.product.initialSalePriceValue !== null) {
        if (this.piece === this.product.stockQuantity + 1) {
          this.piece = this.product.stockQuantity
        } else {
          this.$http
              .get(
                  Constants.apiMethod.generateBasketItem
                      .concat("productId=")
                      .concat(this.product.id)
                      .concat("&quantity=")
                      .concat(this.piece)
                      .concat("&unit=")
                      .concat(this.product.packageUnit)
              )
              .then((response) => {
                // console.log(response.data)
                this.generatedProduct = response.data;
                console.log(response.data)
                this.proWebBasket = JSON.parse(
                    localStorage.getItem("proWebBasket")
                );
                if (
                    this.proWebBasket.find(
                        (s) => s.product.code === this.generatedProduct.product.code
                    )
                ) {
                  this.index = this.proWebBasket.findIndex(
                      (s) => s.product.code === this.generatedProduct.product.code
                  );
                  this.proWebBasket[this.index] = this.generatedProduct;
                  localStorage.setItem(
                      "proWebBasket",
                      JSON.stringify(this.proWebBasket)
                  );
                } else {
                  this.proWebBasket.push(this.generatedProduct);
                  localStorage.setItem(
                      "proWebBasket",
                      JSON.stringify(this.proWebBasket)
                  );
                  this.text = this.$t("add_basket_item_success_message");
                  this.snackbar = true;
                }
                this.$store.state.badgeCounter = this.proWebBasket.length;
              });

        }
      }

      console.log(this.piece);
    },
  },
};
</script>

<style scoped>
.box {
  position: relative;
  /*max-width: 600px;*/
  /*width: 90%;*/
  /*height: 400px;*/
  /*background: #fff;*/
  /*box-shadow: 0 0 15px rgba(0,0,0,.1);*/
}

/* common */
.box {
  position: relative;
  cursor: pointer;
  /*max-width: 600px;*/
  /*width: 90%;*/
  /*height: 400px;*/
  /*background: #fff;*/
  /*box-shadow: 0 0 15px rgba(0,0,0,.1);*/
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 4.1px solid #36733c;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #388e3c;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -9px;
  left: -11px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}

.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}

.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

.ucnokta {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
