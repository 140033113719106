<template>
  <page-template>
    <template slot="title">{{ this.$t("search_results") }}</template>
    <template slot="content">
      <v-layout align-center justify-start row wrap style="margin: 0;">
        <product-view
          v-for="product in $store.state.productSearchConstants
            .productSearchResult"
          :key="product.id"
          style="margin: 0px;"
          :product="product"
          v-show="notFound"
        />
        <not-found
          v-show="
            !$store.state.productSearchConstants.productSearchResult.length
          "
        />
      </v-layout>
    </template>
  </page-template>
</template>

<script>
import PageTemplate from "../../templates/PageTemplate";
import ProductView from "../../partial/ProductView";
import NotFound from "../scada/NotFound";
export default {
  name: "SearchProduct",
  components: { PageTemplate, ProductView, NotFound },
  data() {
    return {
      products: {},
      notFound: true,
    };
  },
  created() {
    this.$store.dispatch("searchProduct");
  },
};
</script>

<style scoped></style>
